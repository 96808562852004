const getBox = ({ x = 32, y = 20, width = 168, height = 165 } = {}) => {
  return {
    type: 'Container',
    components: [],
    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
    layout: {
      width,
      height,
      x,
      y,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: 'mobile.core.components.Container',
    style: {
      type: 'ComponentStyle',
      metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
      style: {
        properties: {
          'alpha-bg': '1',
          'alpha-brd': '1',
          bg: '#AAE7C0',
          'boxShadowToggleOn-shd': 'false',
          brd: '#AAE7C0',
          brw: '0',
          rd: '0px',
          shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
        },
        propertiesSource: {
          'alpha-bg': 'value',
          'alpha-brd': 'value',
          bg: 'value',
          'boxShadowToggleOn-shd': 'value',
          brd: 'value',
          brw: 'value',
          rd: 'value',
          shd: 'value',
        },
        groups: {},
      },
      componentClassName: 'mobile.core.components.Container',
      pageId: '',
      compId: '',
      styleType: 'custom',
      skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
    },
    mobileStructure: {
      layout: {
        width,
        height,
        x,
        y,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      metaData: {
        originalCompId: 'comp-kcg806m4', // TODO ????????
        author: 'duplicate',
      },
    },
    activeModes: {},
  };
};

export default getBox;
