import { isLayoutProGallery } from '@wix/communities-blog-client-common';
import { EditorAppContext } from '../../../types/editor-app-context.type';
import { blogAppDefId } from '../constants/apps';
import {
  getStyleProperty,
  HOMEPAGE_LAYOUT_TYPE_STYLE_PARAM_FULL,
} from '../constants/style-params';
import { TPA_PAGE_ID_POST } from '../constants/tpa-pages';
import { BLOG_WIDGET_ID, POST_WIDGET_ID } from '../constants/widgets';
import bi from './bi';
import { getComponentRef } from './magic-migration/sdk-utils';
import mapStyleParamsToPG from './map-pg-layout-styles';
import page from './page';

export const initBiService = async (context: EditorAppContext) => {
  await bi.init(context);
};

export const setStyleParams = async ({
  sdk,
  appToken,
  isADI,
  isClassicEditor,
}: EditorAppContext) => {
  if (isADI) {
    return;
  }

  const blogAppData = await sdk.tpa.app.getDataByAppDefId(
    appToken,
    blogAppDefId,
  );
  const blogAppComponents =
    await sdk.document.tpa.app.getAllCompsByApplicationId(
      appToken,
      blogAppData.applicationId,
    );
  if (!blogAppComponents) {
    return;
  }

  const blogComponent = blogAppComponents.find(
    (component) => component.widgetId === BLOG_WIDGET_ID,
  );
  if (!blogComponent) {
    return;
  }
  const blogComponentRef = await sdk.document.components.getById(appToken, {
    id: blogComponent.id,
  });
  const blogComponentStyle = await sdk.components.style.get(appToken, {
    componentRef: blogComponentRef,
  });

  const homepageLayoutType = getStyleProperty(
    blogComponentStyle,
    HOMEPAGE_LAYOUT_TYPE_STYLE_PARAM_FULL,
  );

  if (isLayoutProGallery(homepageLayoutType)) {
    return;
  }

  const styleParams = mapStyleParamsToPG(blogComponentStyle, isClassicEditor);

  if (styleParams.length < 1) {
    return;
  }

  await sdk.document.tpa.setStyleParams(appToken, {
    compRef: blogComponentRef,
    styleParams,
  });
};

export const openPostPageSettings = async (
  context: EditorAppContext,
  title?: string,
) => {
  const { sdk, appToken } = context;
  const widgetId = POST_WIDGET_ID;
  const appData = await sdk.tpa.app.getDataByAppDefId(appToken, blogAppDefId);
  const instance = await sdk.document.info.getAppInstance(appToken);
  const componentRef = await getComponentRef(sdk, widgetId);

  if (!componentRef) {
    return;
  }

  const appSettingsUrl = appData.widgets[widgetId].settings.urlV2;
  const url = `${appSettingsUrl}?instance=${instance}&appDefinitionId=${blogAppDefId}&compId=${componentRef.id}`;

  const blogPage = await page.find({
    ...context,
    tpaPageId: TPA_PAGE_ID_POST,
  });

  await sdk.document.pages.navigateTo(appToken, {
    pageRef: blogPage as any,
  });

  sdk.editor.openComponentPanel(appToken, {
    url,
    title,
    type: sdk.editor.PanelType.Settings,
    componentRef,
    width: 404,
    height: appData.settingsHeight,
  });
};

export const openBlogPagesPanel = async ({
  sdk,
  appToken,
}: EditorAppContext) => {
  const { check, show } = sdk.editor.deeplink;
  const feature = {
    type: 'pagesPanel',
    params: [blogAppDefId] as string[],
  } as const;

  const deepLinkToBlogPagesPanel = await check(appToken, feature);

  if (deepLinkToBlogPagesPanel) {
    await show(appToken, feature);
  }
};
