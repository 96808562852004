import { calculateTOILayoutWidth } from './utils';

export class StyleMapper {
  constructor(customFeed) {
    this.customFeed = { ...customFeed };
    this.style = this.customFeed.style.style || {
      properties: {},
      propertiesSource: {},
    };
    this.propsToDelete = [];
  }

  mapLayoutWidth(columnWidth) {
    this.customFeed.layout.width = calculateTOILayoutWidth(
      columnWidth,
      Number(this.customFeed.layout.width),
    );
    return this;
  }

  mapCategory(categories, categoryId) {
    let tpaData = this.customFeed?.data?.tpaData?.content;

    if (!tpaData) {
      return this;
    }

    tpaData = JSON.parse(tpaData);

    if (
      (!tpaData['widget-categoryId'] ||
        tpaData['widget-categoryId'] === 'all-posts') &&
      (!categoryId || categoryId === 'all-posts')
    ) {
      return this;
    }

    const id = categoryId || tpaData['widget-categoryId'];

    if (!id) {
      return this;
    }

    tpaData.categoryId = id;

    this.customFeed.data.tpaData.content = JSON.stringify(tpaData);
    return this;
  }

  mapStyle(
    sourceProp,
    destinationProp,
    { formatter = null, shouldDelete = true } = {},
  ) {
    let value = this.style?.properties?.[sourceProp];

    if (typeof value === 'undefined') {
      return this;
    }

    const sourceValue = this.style.propertiesSource[sourceProp];

    if (formatter) {
      value = formatter(value);
    }

    if (shouldDelete) {
      this.propsToDelete.push(sourceProp);
    }

    this.style.properties[destinationProp] = value;
    this.style.propertiesSource[destinationProp] = sourceValue;
    return this;
  }

  mapFont(sourceProp, destinationProp, overrides = {}) {
    let font;

    try {
      font = JSON.parse(this.style.properties[sourceProp]);
    } catch (e) {}

    if (!font) {
      return this;
    }

    this.propsToDelete.push(sourceProp);
    this.style.properties[destinationProp] = JSON.stringify({
      ...font,
      ...overrides,
    });
    this.style.propertiesSource[destinationProp] = 'value';
    return this;
  }

  mapColor(sourceProp, destinationProp) {
    this.mapStyle(sourceProp, destinationProp);

    const alphaProp = `alpha-${sourceProp}`;
    const value = this.style?.properties?.[alphaProp];

    if (typeof value === 'undefined') {
      return this;
    }

    const sourceValue = this.style.propertiesSource[alphaProp];
    this.propsToDelete.push(alphaProp);

    const alphaDestinationProp = `alpha-${destinationProp}`;
    this.style.properties[alphaDestinationProp] = value;
    this.style.propertiesSource[alphaDestinationProp] = sourceValue;

    return this;
  }

  setStyle(prop, value, source = 'value') {
    if (typeof value === 'function') {
      this.style.properties[prop] = value();
    } else {
      this.style.properties[prop] = value;
    }

    this.style.propertiesSource[prop] = source;
    return this;
  }

  build() {
    this.propsToDelete.forEach((prop) => {
      delete this.style.properties[prop];
      delete this.style.propertiesSource[prop];
    });

    return this.customFeed;
  }
}
