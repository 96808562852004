import { get } from 'lodash';
import { RELATIVE_API_URL } from '../constants/api';
import { oldBlogAppDefId } from '../constants/apps';
import {
  NEW_BLOG_QUICK_MIGRATION,
  NEW_BLOG_MAGIC_MIGRATION,
} from '../constants/experiments';
import { MIGRATION_STEPS } from '../constants/magic-migration-steps';
import { AUTOPILOT_USER_UUID } from '../constants/users';
import { initBiService } from './actions';
import bi from './bi';
import experiments from './experiments';
import { getSiteMemberId } from './instance';
import MagicMigration from './magic-migration';
import monitoring from './monitoring';
import retry, { repeat } from './retry';

const MIGRATION_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  CONTENT_SYNC_STARTED: 'CONTENT_SYNC_STARTED',
  CONTENT_SYNC_FAILED: 'CONTENT_SYNC_FAILED',
  CONTENT_SYNC_COMPLETE: 'CONTENT_SYNC_COMPLETE',
  REDIRECTS_SET_COMPLETE: 'REDIRECTS_SET_COMPLETE',
};

const MAGIC_MIGRATION_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  STARTED: 'MAGIC_MIGRATION_STARTED',
  CONTENT_SYNCED: 'MAGIC_MIGRATION_CONTENT_SYNCED',
  FAILED: 'MAGIC_MIGRATION_FAILED',
  SUCCEEDED: 'MAGIC_MIGRATION_SUCCEEDED',
};

const isQuickMigrationEnabled = () =>
  experiments.isEnabled(NEW_BLOG_QUICK_MIGRATION, 'new');
const isMagicMigrationEnabled = () =>
  experiments.isEnabled(NEW_BLOG_MAGIC_MIGRATION, 'new');

const getIsOldBlogInstalled = async (sdk) => {
  const componentsRef = await sdk.document.components.getAllComponents('');

  const componentsData = await Promise.all(
    componentsRef.map((componentRef) =>
      sdk.document.components.data.get('', { componentRef }),
    ),
  );

  const oldBlogPageData = componentsData.find(
    (componentData) => get(componentData, 'appPageId') === oldBlogAppDefId,
  );

  return Boolean(oldBlogPageData);
};

const shouldMigrateOldBlog = async ({ sdk, isADI, appToken }) => {
  const userId = await getSiteMemberId(sdk, appToken);
  if (isADI || userId === AUTOPILOT_USER_UUID) {
    return false;
  }

  const canMigrate = isQuickMigrationEnabled() || isMagicMigrationEnabled();

  if (!canMigrate) {
    return false;
  }

  const isOldBlogInstalled = await getIsOldBlogInstalled(sdk);
  if (!isOldBlogInstalled) {
    return false;
  }

  return true;
};

const getContentMigrationApiUrl = () =>
  `${RELATIVE_API_URL}/_api/content-migration`;

const startMigration = ({ instance, setRedirects = true } = {}) => {
  const requestUrl = isMagicMigrationEnabled()
    ? `${RELATIVE_API_URL}/_api/magic-migration/sync-content?viewMode=editor`
    : `${getContentMigrationApiUrl()}/transfer-content?viewMode=editor&redirectOnTransferCompletion=${setRedirects}`;

  return fetch(requestUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', instance },
  })
    .then((res) => {
      if (res.status === 404) {
        throw new Error(`Blog not found: ${instance}`);
      }
      return res.ok;
    })
    .catch((err) => Promise.reject(err));
};

const getMigrationStatus = (instance) =>
  fetch(`${getContentMigrationApiUrl()}/details?viewMode=editor`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', instance },
  })
    .then((res) => {
      if (!res.ok) {
        return Promise.reject(new Error(res.statusText));
      }
      return res.json();
    })
    .then(({ status }) => status);

const markMigrationSuccess = async (instance, skipComponentMapping = false) =>
  fetch(
    `${RELATIVE_API_URL}/_api/magic-migration/mark-succeeded?viewMode=editor&skipComponentMapping=${skipComponentMapping}`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', instance },
    },
  ).then((res) => {
    if (!res.ok) {
      return Promise.reject(new Error(res.statusText));
    }
    return res.json();
  });

const markMigrationFailure = async (instance) =>
  fetch(
    `${RELATIVE_API_URL}/_api/magic-migration/mark-failed?viewMode=editor`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', instance },
    },
  ).then((res) => {
    if (!res.ok) {
      return Promise.reject(new Error(res.statusText));
    }
    return res.json();
  });

const checkMigrationStatus = (instance) => async (stopChecking) => {
  const status = await retry(() => getMigrationStatus(instance), 3);

  if (
    isMagicMigrationEnabled() &&
    [
      MAGIC_MIGRATION_STATUS.CONTENT_SYNCED,
      MAGIC_MIGRATION_STATUS.FAILED,
      MAGIC_MIGRATION_STATUS.SUCCEEDED,
    ].includes(status)
  ) {
    stopChecking();
  } else if (
    [
      MIGRATION_STATUS.CONTENT_SYNC_FAILED,
      MIGRATION_STATUS.REDIRECTS_SET_COMPLETE,
    ].includes(status)
  ) {
    stopChecking();
  }

  return status;
};

const migrateOldBlog = async (
  context,
  { skipComponentMapping = false, provisionInitialized = true } = {},
) => {
  return new Promise(async (resolve, reject) => {
    let migrationStep = MIGRATION_STEPS.NOT_STARTED;
    const instance = await context.sdk.document.info.getAppInstance('');

    let status;
    let error = provisionInitialized ? null : 'Save on provisioning failed';

    if (!error) {
      try {
        await retry(
          () =>
            startMigration({
              instance,
              setRedirects: !isMagicMigrationEnabled(),
            }),
          6,
          5000,
        );

        migrationStep = MIGRATION_STEPS.CONTENT_MIGRATION_STARTED;

        const delay = 15000;
        const times = 80;
        status = await repeat(checkMigrationStatus(instance), times, delay);
      } catch (err) {
        error = err;
      }
    }

    if (isMagicMigrationEnabled()) {
      let magicMigration;
      const hasMigrationSucceded =
        status === MAGIC_MIGRATION_STATUS.CONTENT_SYNCED ||
        status === MAGIC_MIGRATION_STATUS.SUCCEEDED;
      if (!error && hasMigrationSucceded) {
        try {
          await monitoring.toMonitored(
            'magic-migration',
            (async () => {
              if (!skipComponentMapping) {
                magicMigration = new MagicMigration(context);
                await magicMigration.run();
              }

              await retry(
                () => markMigrationSuccess(instance, skipComponentMapping),
                3,
              );
            })(),
            true,
          );
        } catch (err) {
          error = err;
        }
      }

      if (error || !hasMigrationSucceded) {
        try {
          migrationStep =
            (magicMigration && magicMigration.getStep()) || migrationStep;

          const errorStack = error instanceof Error ? error.stack : error;
          const errorMessage =
            hasMigrationSucceded || !status
              ? errorStack
              : `Content migration failed, status: ${status}`;
          await bi.magicMigrationFailed(errorMessage, migrationStep);
          await retry(() => markMigrationFailure(instance), 3);
        } catch (_) {}
      }
    }

    error ? reject(error) : resolve(status);
  });
};

const publishSite = async (instance) =>
  fetch(
    `${RELATIVE_API_URL}/_api/magic-migration/publish-on-ultimate-migration`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', instance },
    },
  );

const migrateOffline = async (context) => {
  let migrationStep = MIGRATION_STEPS.NOT_STARTED;
  let magicMigration;
  let error;

  try {
    await monitoring.toMonitored(
      'magic-migration',
      (async () => {
        magicMigration = new MagicMigration(context);
        await magicMigration.run({ isOffline: true });

        const instance = await context.sdk.document.info.getAppInstance('');
        await retry(context.sdk.document.save);
        await retry(() => publishSite(instance));
      })(),
      true,
    );
  } catch (err) {
    error = err;
  }

  if (error) {
    const errorStack = error instanceof Error ? error.stack : error;
    migrationStep =
      (magicMigration && magicMigration.getStep()) || migrationStep;
    await initBiService(context);
    await bi.magicMigrationFailed(errorStack, migrationStep);
    throw error;
  }
};

export default {
  shouldMigrate: shouldMigrateOldBlog,
  migrate: migrateOldBlog,
  migrateOffline,
};
