import {
  type ElementsPanelParams,
  openElementsPanel,
} from '../../blocks/common/elements-panel';
import { EditorAppContext } from '../../types/editor-app-context.type';
import { HEADER_COMPS_DATA, CATEGORY_IDS } from './constants';
import { getCountersElements, PanelPlacementEnum } from './getCountersElements';

export const openHeaderElementsPanel = async (
  context: EditorAppContext,
  widgetRef: ElementsPanelParams['widgetRef'],
) => {
  const countersElements = await getCountersElements({
    sdk: context.sdk,
    token: context.appToken,
    placement: PanelPlacementEnum.HEADER,
  });

  return openElementsPanel(context, {
    widgetRef,
    categoriesData: [
      { id: CATEGORY_IDS.mainElements, title: 'Main elements' },
      { id: CATEGORY_IDS.metadata, title: 'Metadata' },
      { id: CATEGORY_IDS.counters, title: 'Counters' },
      { id: CATEGORY_IDS.decorative, title: 'Decorative' },
    ],
    elementsData: [
      {
        label: HEADER_COMPS_DATA.categories.label,
        identifier: { role: HEADER_COMPS_DATA.categories.role },
        categoryId: CATEGORY_IDS.mainElements,
        index: 0,
      },
      {
        label: HEADER_COMPS_DATA.title.label,
        identifier: { role: HEADER_COMPS_DATA.title.role },
        categoryId: CATEGORY_IDS.mainElements,
        index: 1,
      },
      {
        label: HEADER_COMPS_DATA.rating.label,
        identifier: { role: HEADER_COMPS_DATA.rating.role },
        categoryId: CATEGORY_IDS.mainElements,
        index: 2,
      },
      {
        label: HEADER_COMPS_DATA.tags.label,
        identifier: { role: HEADER_COMPS_DATA.tags.role },
        categoryId: CATEGORY_IDS.mainElements,
        index: 3,
      },
      {
        label: HEADER_COMPS_DATA.writer.label,
        identifier: { role: HEADER_COMPS_DATA.writer.role },
        categoryId: CATEGORY_IDS.mainElements,
        index: 4,
      },
      {
        label: HEADER_COMPS_DATA.counters.label,
        identifier: { role: HEADER_COMPS_DATA.counters.role },
        categoryId: CATEGORY_IDS.mainElements,
        index: 5,
      },
      // Metadata section
      {
        label: HEADER_COMPS_DATA.metadataWriter.label,
        identifier: { role: HEADER_COMPS_DATA.metadataWriter.role },
        categoryId: CATEGORY_IDS.metadata,
        index: 0,
      },
      {
        label: HEADER_COMPS_DATA.publishDate.label,
        identifier: { role: HEADER_COMPS_DATA.publishDate.role },
        categoryId: CATEGORY_IDS.metadata,
        index: 1,
      },
      {
        label: HEADER_COMPS_DATA.readingTime.label,
        identifier: { role: HEADER_COMPS_DATA.readingTime.role },
        categoryId: CATEGORY_IDS.metadata,
        index: 2,
      },
      {
        label: HEADER_COMPS_DATA.lastUpdated.label,
        identifier: { role: HEADER_COMPS_DATA.lastUpdated.role },
        categoryId: CATEGORY_IDS.metadata,
        index: 3,
      },
      // Counters section
      ...countersElements,
      // Decorative section
      {
        label: HEADER_COMPS_DATA.divider.label,
        identifier: { role: HEADER_COMPS_DATA.divider.role },
        categoryId: CATEGORY_IDS.decorative,
        index: 0,
      },
    ],
  });
};
