import { RELATIVE_API_URL } from '../../constants/api';

export const fetchCategoryId = (instance, categoryLabel) =>
  fetch(
    `${RELATIVE_API_URL}/_api/categories/label/${encodeURIComponent(
      categoryLabel,
    )}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', instance },
    },
  )
    .then((res) => {
      if (!res.ok) {
        if (res.status === 404) {
          return {};
        }
        throw Error(res.statusText);
      }
      return res.json();
    })
    .then(({ id }) => id);

export const fetchTagId = (instance, tagLabel) =>
  fetch(`${RELATIVE_API_URL}/v2/tags`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      instance,
      Authorization: instance,
    },
    body: JSON.stringify({ label: tagLabel }),
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.statusText);
      }
      return res.json();
    })
    .then(({ tag }) => tag.id);
