import { set, isUndefined, isNil, omitBy, has } from 'lodash';

export default class FontBuilder {
  constructor(widgetBuilder, styleParamName) {
    this.widgetBuilder = widgetBuilder;
    this.styleParamName = styleParamName;
    this.font = {
      preset: 'Custom',
      fontStyleParam: true,
      style: { bold: false, italic: false, underline: false },
    };
  }

  mapValue(from, targetProp, options) {
    const value = this.widgetBuilder.resolveValue(from, options);
    if (!isUndefined(value)) {
      set(this.font, targetProp, value);
    }
    return this;
  }

  setValue(targetProp, value) {
    set(this.font, targetProp, value);
    return this;
  }

  mapTheme(from, options = {}) {
    const { font, editorKey } = this.widgetBuilder.resolveThemeFont(
      from,
      options,
    );
    if (font) {
      this.font.family = font.family;
      this.font.editorKey = editorKey;
      this.font.size = this.widgetBuilder.formatValue(font.size, {
        ...options.size,
        formatter: (x) => parseInt(x, 10),
      });
    }
    return this;
  }

  build() {
    return omitBy(this.font, isNil);
  }

  isValid() {
    return ['family', 'size'].every((key) => has(this.font, key));
  }

  value() {
    if (!this.isValid()) {
      return this.widgetBuilder;
    }
    const styleParams = Array.isArray(this.styleParamName)
      ? this.styleParamName
      : [this.styleParamName];
    styleParams.forEach((styleParamName) =>
      this.widgetBuilder.setValueParam(
        styleParamName,
        JSON.stringify(this.build()),
      ),
    );
    return this.widgetBuilder;
  }
}
