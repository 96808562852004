import type { ElementData } from '@wix/platform-editor-sdk';
import {
  type ElementsPanelParams,
  openElementsPanel,
} from '../../blocks/common/elements-panel';
import type { EditorAppContext } from '../../types/editor-app-context.type';
import commentsJson from '../Comments/.component.json';
import relatedPostsJson from '../Related Posts/.component.json';
import { POST_PAGE_SELECTORS } from './post-page-constants';

const COMPS_DATA = {
  postHeader: { role: POST_PAGE_SELECTORS.POST_HEADER, label: 'Header' },
  postContent: {
    role: POST_PAGE_SELECTORS.POST_CONTENT,
    label: 'Content',
  },
  postFooter: { role: POST_PAGE_SELECTORS.POST_FOOTER, label: 'Footer' },
};

const slotsIds = [relatedPostsJson.id, commentsJson.id];

export const openPostPageElementsPanel = async (
  context: EditorAppContext,
  widgetRef: ElementsPanelParams['widgetRef'],
) => {
  const elements: ElementData[] = [
    {
      label: COMPS_DATA.postHeader.label,
      identifier: { role: COMPS_DATA.postHeader.role },
      index: 0,
    },
    {
      label: COMPS_DATA.postContent.label,
      identifier: { role: COMPS_DATA.postContent.role },
      index: 1,
    },
    {
      label: COMPS_DATA.postFooter.label,
      identifier: { role: COMPS_DATA.postFooter.role },
      index: 2,
    },
  ];

  for (const slotId of slotsIds) {
    const plugins = await context.sdk.document.tpa.widgetPlugins.getWidgetSlots(
      context.appToken,
      { widgetId: slotId },
    );

    plugins.forEach((plugin, index) => {
      const pluginInfo = plugin.pluginInfo;

      if (pluginInfo) {
        elements.push({
          label: pluginInfo.name,
          identifier: { role: plugin.role },
          index: elements.length + index,
        });
      }
    });
  }

  return openElementsPanel(context, {
    widgetRef,
    categoriesData: [],
    elementsData: elements,
  });
};
