import { PanelResolveType } from '@wix/platform-editor-sdk';
import {
  reinstallationModalShown,
  reinstallationModalAction,
} from '@wix/bi-logger-blog-app-users/v2';
import reinstallBlogIllustration from '../assets/reinstall-blog.png';
import { blogAppDefId } from '../external/common/constants/apps';
import { getMetaSiteId } from '../external/common/services/instance';
import translation from '../external/common/services/translation';
import { EditorAppContext } from '../types/editor-app-context.type';

const { translate } = translation;

export async function handleBlogInstallTimeout(context: EditorAppContext) {
  if (context.flowAPI.environment.isADI) {
    return;
  }

  const msid = getMetaSiteId(context?.instance!);

  context.flowAPI.bi?.report(
    reinstallationModalShown({
      entryPoint: `${context?.origin?.type}-${context?.origin?.initiator}-${
        context?.origin?.info?.type ?? 'UNKNOWN'
      }`,
      biToken: msid,
    }),
  );

  const result = await context.sdk.editor.openConfirmationPanel(
    context.appToken,
    {
      illustration: reinstallBlogIllustration,
      shouldShowIllustration: true,
      headerText: translate('installation-timeout.title'),
      descriptionText: translate('installation-timeout.description'),
      mainActionText: translate('installation-timeout.mainAction'),
      secondaryActionText: translate('installation-timeout.secondaryAction'),
    },
  );

  context.flowAPI.bi?.report(
    reinstallationModalAction({
      action: result === PanelResolveType.MAIN_ACTION ? 'reinstall' : 'delete',
      biToken: msid,
    }),
  );

  if (result === PanelResolveType.MAIN_ACTION) {
    await context.sdk.application.uninstall(context.appToken, {
      openConfirmation: false,
    });

    await context.sdk.tpa.add.application(context.appToken, {
      appDefinitionId: blogAppDefId,
      shouldNavigate: true,
      showPageAddedPanel: true,
    });
  } else if (result === PanelResolveType.SECONDARY_ACTION) {
    await context.sdk.application.uninstall(context.appToken, {
      openConfirmation: false,
    });
  }
}
