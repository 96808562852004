const COMMON_SELECTORS = {
  CATEGORIES: 'categories1',
  TAGS: 'tags1',
  METADATA: 'metadata1',
  WRITER: 'text1',
  PUBLISH_DATE: 'text5',
  READING_TIME: 'text2',
  LAST_UPDATED: 'text3',
};

const POST_HEADER_SELECTORS = {
  ...COMMON_SELECTORS,
  TITLE: 'title1',
  RATINGS: 'ratings1',
  LIKES: 'likesDefault',
  VIEWS: 'viewsContainer',
  COMMENTS: 'commentsContainer',
  DIVIDER: 'dividerRoot',
  COUNTERS: 'postCountersNew1',
};

const POST_FOOTER_SELECTORS = {
  ...COMMON_SELECTORS,
  LIKES: 'statesContainer',
  VIEWS: 'viewsContainer',
  COMMENTS: 'commentsContainer',
  DIVIDER: 'dividerRoot',
  COUNTERS: 'postCountersNew1',
};

export const HEADER_COMPS_DATA = {
  categories: { role: POST_HEADER_SELECTORS.CATEGORIES, label: 'Categories' },
  title: { role: POST_HEADER_SELECTORS.TITLE, label: 'Title' },
  rating: { role: POST_HEADER_SELECTORS.RATINGS, label: 'Ratings' },
  tags: { role: POST_HEADER_SELECTORS.TAGS, label: 'Tags' },
  writer: { role: POST_HEADER_SELECTORS.METADATA, label: 'Writer' },
  counters: { role: POST_HEADER_SELECTORS.COUNTERS, label: 'Counters' },

  // metadata
  metadataWriter: { role: POST_HEADER_SELECTORS.WRITER, label: 'Writer' },
  publishDate: {
    role: POST_HEADER_SELECTORS.PUBLISH_DATE,
    label: 'Publish date',
  },
  readingTime: {
    role: POST_HEADER_SELECTORS.READING_TIME,
    label: 'Reading time',
  },
  lastUpdated: {
    role: POST_HEADER_SELECTORS.LAST_UPDATED,
    label: 'Last updated',
  },

  // counters
  likes: { role: POST_HEADER_SELECTORS.LIKES, label: 'Likes' },
  views: { role: POST_HEADER_SELECTORS.VIEWS, label: 'Views' },
  comments: { role: POST_HEADER_SELECTORS.COMMENTS, label: 'Comments' },

  // decorative
  divider: { role: POST_HEADER_SELECTORS.DIVIDER, label: 'Divider' },
};

export const FOOTER_COMPS_DATA = {
  categories: { role: POST_FOOTER_SELECTORS.CATEGORIES, label: 'Categories' },
  tags: { role: POST_FOOTER_SELECTORS.TAGS, label: 'Tags' },
  writer: { role: POST_FOOTER_SELECTORS.METADATA, label: 'Writer' },
  counters: { role: POST_FOOTER_SELECTORS.COUNTERS, label: 'Counters' },

  // metadata
  metadataWriter: { role: POST_FOOTER_SELECTORS.WRITER, label: 'Writer' },
  publishDate: {
    role: POST_FOOTER_SELECTORS.PUBLISH_DATE,
    label: 'Publish date',
  },
  readingTime: {
    role: POST_FOOTER_SELECTORS.READING_TIME,
    label: 'Reading time',
  },
  lastUpdated: {
    role: POST_FOOTER_SELECTORS.LAST_UPDATED,
    label: 'Last updated',
  },

  // counters
  likes: { role: POST_FOOTER_SELECTORS.LIKES, label: 'Likes' },
  views: { role: POST_FOOTER_SELECTORS.VIEWS, label: 'Views' },
  comments: { role: POST_FOOTER_SELECTORS.COMMENTS, label: 'Comments' },

  // decorative
  divider: { role: POST_FOOTER_SELECTORS.DIVIDER, label: 'Divider' },
};

export const CATEGORY_IDS = {
  mainElements: 'mainElements',
  metadata: 'metadata',
  counters: 'counters',
  decorative: 'decorative',
} as const;
