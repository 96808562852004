import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder) => {
  widgetBuilder.set({
    displayName: 'Widget Post Categories',
    nickname: 'Post CategoriesWidget',
  });

  widgetBuilder.gfpp().set('connect', { behavior: 'HIDE' });

  widgetBuilder.configureConnectedComponents(
    'button',
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName: 'Categories Button',
      });

      connectedComponentsBuilder
        .gfpp()
        .set('mainAction1', { behavior: 'HIDE' })
        .set('mainAction2', { behavior: 'HIDE' })
        .set('settings', { behavior: 'HIDE' })
        .set('design', { behavior: 'HIDE' });
    },
  );
};
