import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder) => {
  widgetBuilder.set({
    displayName: 'Widget Post Share Buttons',
    nickname: 'Post Share ButtonsWidget',
  });

  widgetBuilder.configureConnectedComponents('shareButtons', (builder) => {
    builder.gfpp().set('mainAction2', { behavior: 'HIDE' });
  });
};
